import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function MobileMenu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuExpand, setSubmenuExpand] = useState(null);
  const [submenuExpand2nd, setSubmenuExpand2nd] = useState(null);
  return (
    <>
      <div className="sm_menu">
        <div className="mobile-header-section d-block d-lg-none">
          <div className="mobile-header-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <div className="mobile-header--left">
                    <Link
                      onClick={() => setMenuOpen(!menuOpen)}
                      to="/"
                      className="mobile-logo-link"
                    >
                      <img src="assets/image/logo.png" alt="" />
                    </Link>
                  </div>
                  <div className="mobile-header--right">
                    <Link
                      onClick={() => setMenuOpen(!menuOpen)}
                      className="mobile-menu offcanvas-toggle"
                    >
                      <span className="mobile-menu-dash"></span>
                      <span className="mobile-menu-dash"></span>
                      <span className="mobile-menu-dash"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="mobile-menu-offcanvas"
          className={
            menuOpen === false
              ? "offcanvas offcanvas-leftside offcanvas-mobile-menu-section"
              : "offcanvas offcanvas-leftside offcanvas-mobile-menu-section offcanvas-open"
          }
        >
          <div className="offcanvas-header text-right">
            <button
              className="offcanvas-close"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="offcanvas-mobile-menu-wrapper">
            <Link to={"/"} className="mobile-menu-top">
              <span>
                <img src="assets/image/logo.png" alt="" />
              </span>
            </Link>
            <div className="mobile-menu-center">
              <div className="offcanvas-menu">
                <ul>
                  <li>
                    <Link onClick={() => setMenuOpen(!menuOpen)} to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setMenuOpen(!menuOpen)}
                      to="/portfolio"
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li className={submenuExpand === "services" ? "active" : ""}>
                    <div
                      className="offcanvas-menu-expand"
                      onClick={() =>
                        setSubmenuExpand(
                          submenuExpand === "services" ? "null" : "services"
                        )
                      }
                    ></div>
                    <Link>
                      <span
                        onClick={() =>
                          setSubmenuExpand(
                            submenuExpand === "services" ? "null" : "services"
                          )
                        }
                      >
                        Services
                      </span>
                    </Link>
                    <ul
                      className="mobile-sub-menu"
                      style={
                        submenuExpand === "services"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li
                        className={
                          submenuExpand2nd === "design-branding" ? "active" : ""
                        }
                      >
                        <div
                          className="offcanvas-menu-expand"
                          onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "design-branding"
                                ? "null"
                                : "design-branding"
                            )
                          }
                        ></div>
                        <Link
                          onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "design-branding"
                                ? "null"
                                : "design-branding"
                            )
                          }
                        >
                          Design & Branding
                        </Link>
                        <ul
                          className="mobile-sub-menu"
                          style={
                            submenuExpand2nd === "design-branding"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/brand-identity"
                            >
                              Brand Identity
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/logo-design"
                            >
                              Logo Design
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/corporate-identity"
                            >
                              Corporate Identity
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/brochure-design"
                            >
                              Brochure Design
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/explainer-video"
                            >
                              Explainer Video
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/content-writing"
                            >
                              Content Writing
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul
                      className="mobile-sub-menu"
                      style={
                        submenuExpand === "services"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li
                        className={
                          submenuExpand2nd === "web-solutions" ? "active" : ""
                        }
                      >
                        <div
                          className="offcanvas-menu-expand"
                          onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "web-solutions"
                                ? "null"
                                : "web-solutions"
                            )
                          }
                        ></div>
                        <Link onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "web-solutions"
                                ? "null"
                                : "web-solutions"
                            )
                          }>Web Solutions</Link>
                        <ul
                          className="mobile-sub-menu"
                          style={
                            submenuExpand2nd === "web-solutions"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/website-design"
                            >
                              Website Design
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/web-app-developement"
                            >
                              Web Application Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/small-business-website"
                            >
                              Small Business Website
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul
                      className="mobile-sub-menu"
                      style={
                        submenuExpand === "services"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li
                        className={
                          submenuExpand2nd === "ecommerce-development"
                            ? "active"
                            : ""
                        }
                      >
                        <div
                          className="offcanvas-menu-expand"
                          onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "ecommerce-development"
                                ? "null"
                                : "ecommerce-development"
                            )
                          }
                        ></div>
                        <Link onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "ecommerce-development"
                                ? "null"
                                : "ecommerce-development"
                            )
                          }>
                          Ecommerce Development
                        </Link>
                        <ul
                          className="mobile-sub-menu"
                          style={
                            submenuExpand2nd === "ecommerce-development"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/ecommerce-web-design"
                            >
                              eCommerce Website Design
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/ecommerce-app"
                            >
                              eCommerce App
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul
                      className="mobile-sub-menu"
                      style={
                        submenuExpand === "services"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li
                        className={
                          submenuExpand2nd === "app-development" ? "active" : ""
                        }
                      >
                        <div
                          className="offcanvas-menu-expand"
                          onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "app-development"
                                ? "null"
                                : "app-development"
                            )
                          }
                        ></div>
                        <Link onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "app-development"
                                ? "null"
                                : "app-development"
                            )
                          }>
                          App Development
                        </Link>
                        <ul
                          className="mobile-sub-menu"
                          style={
                            submenuExpand2nd === "app-development"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/mobile-app-dev"
                            >
                              Mobile App Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/android-app-dev"
                            >
                              Android App Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/ios-app-dev"
                            >
                              iOS App Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/react-native-dev"
                            >
                              React Native Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/hybrid-app-dev"
                            >
                              Hybrid App Development
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul
                      className="mobile-sub-menu"
                      style={
                        submenuExpand === "services"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li
                        className={
                          submenuExpand2nd === "marketing-lead" ? "active" : ""
                        }
                      >
                        <div
                          className="offcanvas-menu-expand"
                          onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "marketing-lead"
                                ? "null"
                                : "marketing-lead"
                            )
                          }
                        ></div>
                        <Link onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "marketing-lead"
                                ? "null"
                                : "marketing-lead"
                            )
                          }>
                          Marketing & Lead Generation
                        </Link>
                        <ul
                          className="mobile-sub-menu"
                          style={
                            submenuExpand2nd === "marketing-lead"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/digital-marketing"
                            >
                              Digital Marketing
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/seo-services"
                            >
                              SEO Services
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/social-media-markeing"
                            >
                              Social Media Marketing
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/ppc-managementl"
                            >
                              PPC Management
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/local-seo"
                            >
                              Local SEO
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/content-marketing"
                            >
                              Content Marketing Services
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={submenuExpand === "technology" ? "active" : ""}
                  >
                    <div
                      className="offcanvas-menu-expand"
                      onClick={() =>
                        setSubmenuExpand(
                          submenuExpand === "technology" ? "null" : "technology"
                        )
                      }
                    ></div>
                    <Link>
                      <span
                        onClick={() =>
                          setSubmenuExpand(
                            submenuExpand === "technology"
                              ? "null"
                              : "technology"
                          )
                        }
                      >
                        Technology
                      </span>
                    </Link>
                    <ul
                      className="mobile-sub-menu"
                      style={
                        submenuExpand === "technology"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li
                        className={
                          submenuExpand2nd === "web-technology" ? "active" : ""
                        }
                      >
                        <div
                          className="offcanvas-menu-expand"
                          onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "web-technology"
                                ? "null"
                                : "web-technology"
                            )
                          }
                        ></div>
                        <Link onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "web-technology"
                                ? "null"
                                : "web-technology"
                            )
                          }>
                          Web Technolgies
                        </Link>
                        <ul
                          className="mobile-sub-menu"
                          style={
                            submenuExpand2nd === "web-technology"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/nodejs-dev"
                            >
                              Node JS Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/angular-dev"
                            >
                              Angular Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/react-dev"
                            >
                              React Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/php-dev"
                            >
                              PHP Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/mongodb-dev"
                            >
                              MongoDB
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul
                      className="mobile-sub-menu"
                      style={
                        submenuExpand === "technology"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li
                        className={
                          submenuExpand2nd === "cms-technology" ? "active" : ""
                        }
                      >
                        <div
                          className="offcanvas-menu-expand"
                          onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "cms-technology"
                                ? "null"
                                : "cms-technology"
                            )
                          }
                        ></div>
                        <Link onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "cms-technology"
                                ? "null"
                                : "cms-technology"
                            )
                          }>
                          CMS Technolgies
                        </Link>
                        <ul
                          className="mobile-sub-menu"
                          style={
                            submenuExpand2nd === "cms-technology"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/wordpress-dev"
                            >
                              Wordpress Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/drupal-dev"
                            >
                              Drupal Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/joomla-dev"
                            >
                              Joomla Development
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul
                      className="mobile-sub-menu"
                      style={
                        submenuExpand === "technology"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li
                        className={
                          submenuExpand2nd === "ecommerce-technology"
                            ? "active"
                            : ""
                        }
                      >
                        <div
                          className="offcanvas-menu-expand"
                          onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "ecommerce-technology"
                                ? "null"
                                : "ecommerce-technology"
                            )
                          }
                        ></div>
                        <Link onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "ecommerce-technology"
                                ? "null"
                                : "ecommerce-technology"
                            )
                          }>
                          eCommerce Technologies
                        </Link>
                        <ul
                          className="mobile-sub-menu"
                          style={
                            submenuExpand2nd === "ecommerce-technology"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/magento-dev"
                            >
                              Magento Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/shopify-dev"
                            >
                              Shopify Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/big-commerce-dev"
                            >
                              BigCommerce
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/woo-commerce-dev"
                            >
                              WooCommerce Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="open-cart-dev"
                            >
                              OpenCart Development
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul
                      className="mobile-sub-menu"
                      style={
                        submenuExpand === "technology"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li
                        className={
                          submenuExpand2nd === "mobile-technology"
                            ? "active"
                            : ""
                        }
                      >
                        <div
                          className="offcanvas-menu-expand"
                          onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "mobile-technology"
                                ? "null"
                                : "mobile-technology"
                            )
                          }
                        ></div>
                        <Link  onClick={() =>
                            setSubmenuExpand2nd(
                              submenuExpand2nd === "mobile-technology"
                                ? "null"
                                : "mobile-technology"
                            )
                          } >
                          Mobile Technologies
                        </Link>
                        <ul
                          className="mobile-sub-menu"
                          style={
                            submenuExpand2nd === "mobile-technology"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/android-app-dev"
                            >
                              Andriod Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/ios-app-dev"
                            >
                              iOS Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/react-native-dev"
                            >
                              React Native Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/swift-dev"
                            >
                              Swift Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/jquery-mobile"
                            >
                              jQuery Mobile
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => setMenuOpen(!menuOpen)}
                              to="/iconic-dev"
                            >
                              Iconic Development
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link onClick={() => setMenuOpen(!menuOpen)} to="/clients">
                      Clients
                    </Link>
                  </li>

                  <li className={submenuExpand === "hire-us" ? "active" : ""}>
                    <div
                      className="offcanvas-menu-expand"
                      onClick={() =>
                        setSubmenuExpand(
                          submenuExpand === "hire-us" ? "null" : "hire-us"
                        )
                      }
                    ></div>
                    <Link>
                      <span
                        onClick={() =>
                          setSubmenuExpand(
                            submenuExpand === "hire-us" ? "null" : "hire-us"
                          )
                        }
                      >
                        Hire Us
                      </span>
                    </Link>
                    <ul
                      className="mobile-sub-menu"
                      style={
                        submenuExpand === "hire-us"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/hire-angular-dev"
                        >
                          Hire Angular Developer
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/hire-node-dev"
                        >
                          Hire Node JS Developer
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/hire-laravel-dev"
                        >
                          Hire Laravel Developer
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/hire-php-dev"
                        >
                          Hire PHP Developer
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/hire-wordress-dev"
                        >
                          Hire WordPress Developer
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/hire-android-dev"
                        >
                          Hire Android Developer
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/hire-ios-dev"
                        >
                          Hire iOS Developer
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/hire-renative-dev"
                        >
                          Hire React Native Developer
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className={submenuExpand === "solutions" ? "active" : ""}>
                    <div
                      className="offcanvas-menu-expand"
                      onClick={() =>
                        setSubmenuExpand(
                          submenuExpand === "solutions" ? "null" : "solutions"
                        )
                      }
                    ></div>
                    <Link>
                      <span
                        onClick={() =>
                          setSubmenuExpand(
                            submenuExpand === "solutions" ? "null" : "solutions"
                          )
                        }
                      >
                        Solutions
                      </span>
                    </Link>
                    <ul
                      className="mobile-sub-menu"
                      style={
                        submenuExpand === "solutions"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/food-delivery-app"
                        >
                          Food Delivery App
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/ecommerce-mobile-app"
                        >
                          eCommerce Mobile App
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/elearning-solution"
                        >
                          eLearning Solutions
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/property-listing-solution"
                        >
                          Property Listing Solutions
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/shopping-cart-dev"
                        >
                          Shopping Cart Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setMenuOpen(!menuOpen)}
                          to="/dynamic-web-dev"
                        >
                          Dynamic Web Design
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link onClick={() => setMenuOpen(!menuOpen)} to="/blogs">
                      Blogs
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => setMenuOpen(!menuOpen)} to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

