import axios from "axios";
import baseURL from "../Helper/Helper";

export const AddContact=async(reqData)=>{
    try{
        const resp=await axios.post(`${baseURL}/api/add-message`,reqData);
        const data=resp?.data;
        return data;
    }catch(err){
        console.log(err);
    }
}