import React from 'react'
import OurClient from '../OurClient/OurClient'

export default function SslPrice() {
  return (
    <>
        <div className="inner-page" id="contact-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="inner_content" data-aos="fade-up" data-aos-duration="2000">
                            <h1>SSL</h1>
                            <div aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#"><i className="ri-home-4-line"></i> Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">SSL</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="seo_content_sec">
            <div className="container">
            <div className="row">
                    <div className="col-g-12 text-right">
                        <div className="switch_butt">
                            <div className="form-check form-switch">
                                <label>$</label>
                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                <label>€</label>
                              </div>
                        </div>
                       
                    </div>
                </div>
                <div className="row g-5 justify-content-center mt-3">
                    <div className="col-lg-5">
                        <div className="pricing_card" data-aos="zoom-in" data-aos-duration="2000">
                            <div className="top_header">
                                <h4>Silver</h4>
                                <div className="border_btn"></div>
                                <div className="pirze_sec">
                                    $<span>450</span>
                                </div>
                              
                                <div className="down_arow">
                                    <div className="down_arow_cont"></div>
                                    <i className="fa fa-arrow-down"></i>
                                </div>
                                <div className="over_heading">
                                    <span>Silver</span>
                                </div>
                            </div>
                            <div className="pricing_body">
                                <ul className="pricing_list">

                                    <li><i className="fa fa-check"></i>1 year</li>
  
                                </ul>
                                <a href="" className="btn_buy">BUY NOW</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="pricing_card" data-aos="zoom-in" data-aos-duration="2000">
                            <div className="top_header">
                                <h4>Gold</h4>
                                <div className="border_btn"></div>
                                <div className="pirze_sec">
                                    $<span>1000</span>
                                </div>
                                

                                <div className="down_arow">
                                    <div className="down_arow_cont"></div>
                                    <i className="fa fa-arrow-down"></i>
                                </div>
                                <div className="over_heading">
                                    <span>Gold</span>
                                </div>
                            </div>
                            <div className="pricing_body">
                                <ul className="pricing_list">
                                   
    <li><i className="fa fa-check"></i>3 years</li>

                                </ul>
                                <a href="" className="btn_buy">BUY NOW</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section className="logo_design_project p-0" id="android_app">
            <div className="row g-0 ">
                <div className="col-lg-5">
                    <div className="content_img aos-init aos-animate" data-aos="fade-right" data-aos-duration="2000">
                        <img src="assets/image/call-suprot-img.png" className="img-fluid" alt=""/>
                    </div>
                </div>
                <div className="col-lg-7 text-center">
                    <div className="heading aos-init aos-animate" data-aos="fade-left" data-aos-duration="2000">
                        <h3> Support For 24/7</h3>
                        <p>
                            What can we do for you?<br/>
                            We would be happy to discuss the project with you in person
                        </p>
                        <div className="btn_sec">
                            <a href="" className="btn2">Request a quote</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <OurClient/>
    </>
  )
}
