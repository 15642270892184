import React from 'react'

export default function DynamicWebDev() {
  return (
    <>
      <div
        className="inner-page"
        id="nodejs-design-bg"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <h1>Dynamic Web Design</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">
                        <i className="ri-home-4-line" />
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Dynamic Web Design
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section" data-aos="fade-up" data-aos-duration={1500}>
        <div className="bg-body-tertiary">
          <div className="container py-3 py-lg-4">
            <div className="row align-items-center">
              <div className="col-md-7">
                <h2 className="section-title">
                Discover the Dynamic Features of Our Web Design Solutions{" "}
                </h2>
                <div className="d-flex align-items-center">
                  <i className="ri-pencil-ruler-line icon-color mr-3" />
                  <h5>Engaging User Experiences</h5>
                </div>
                <p className="">
            Our Dynamic Web Design solution offers engaging and interactive user experiences. With innovative design elements and seamless navigation, users can explore your website effortlessly, ensuring a captivating and memorable online journey.
          </p>
                <div className="text-center text-md-left ">
                  <a href="contact.html" className="btn1 mb-2 mb-lg-0">
                    Explore Designs
                  </a>
                </div>
              </div>
              <div className="col-md-5">
                <div className="bg-dotted">
                  <img
                    src="assets/image/desktop.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section bg_color "
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-courses.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="text-white">
                <h5 className="mb-3 ">
                  <img
                    width={64}
                    height={64}
                    src="https://img.icons8.com/ios/64/ffffff/map.png"
                    className="mr-3"
                    alt="map"
                  />
                  Responsive Cross-Platform Design
                </h5>
                <p>
            Ensure a consistent and optimal user experience across all devices. Our dynamic web design features responsive layouts, adapting seamlessly to various screen sizes and resolutions for a unified and accessible presentation.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-class-schedule.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <div className="d-flex align-items-center">
                  <i className="fa fa-calendar icon-color mr-3" />
                  <h5 className=""> Interactive Content Modules</h5>
                </div>
                <p>
            Capture attention with dynamic content modules. Engage users through interactive elements, sliders, and immersive features that make your website not just informative but also entertaining and memorable.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color "
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-assessment.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="text-white">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    src="https://img.icons8.com/ios/50/ffffff/pass.png"
                    className="mr-3"
                    alt="pass"
                  />
                  Effortless Navigation Structures
                </h5>
                <p>
            Simplify user journeys with intuitive navigation. Our dynamic web design ensures a seamless and user-friendly experience, allowing visitors to effortlessly explore your content and find what they're looking for with ease.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-specialists-guidance.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    src="https://img.icons8.com/sf-ultralight-filled/50/0d6efd/chat.png"
                    className="mr-3"
                    alt="chat"
                  />
                  Customizable Design Elements
                </h5>
                <p>
            Tailor your website's appearance to match your brand identity. With our dynamic web design, enjoy the flexibility of customizable design elements, allowing you to create a unique and visually appealing online presence.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section  bg_color"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-customization.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <div className="d-flex align-items-center">
                  <i className="fa fa-cogs icon-color-white mr-3" />
                  <h5 className="">SEO-Optimized Structure</h5>
                </div>
                <p>
            Boost your online visibility with our SEO-optimized dynamic web design. Implement a structure that enhances search engine rankings, making it easier for potential customers to discover your website and its valuable content.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-userfriendly.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={66}
                    height={66}
                    src="https://img.icons8.com/external-3d-design-circle/66/external-Admin-Panel-design-and-development-3d-design-circle.png"
                    alt="external-Admin-Panel-design-and-development-3d-design-circle"
                  />{" "}
                  Performance-Driven Functionality
                </h5>
                <p>
            Elevate user satisfaction with our performance-driven dynamic web design. Enjoy fast-loading pages, efficient functionality, and an overall enhanced performance that ensures visitors have a seamless and enjoyable browsing experience.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color  after-image overflow-hidden"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="mobile-friendly-image solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-mobile-friendly.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="text-white">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    className="mr-3"
                    src="https://img.icons8.com/ios/50/ffffff/media-queries.png"
                    alt="media-queries"
                  />
                  Secure and Robust Architecture
                </h5>
                <p>
            Trust in the security of your website with our dynamic web design. We provide a robust architecture and implement secure measures to safeguard user data and maintain the integrity of your online presence.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-report-generation.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={60}
                    height={60}
                    className="mr-3"
                    src="https://img.icons8.com/color/60/combo-chart--v1.png"
                    alt="combo-chart--v1"
                  />
                  Innovative User Experiences
                </h5>
                <p>
            Redefine user expectations with our dynamic web design. We focus on delivering innovative user experiences that go beyond traditional standards, ensuring that each interaction with your website leaves a lasting and positive impression.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section  mb-10 py-3 py-lg-4"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container">
          <h2 className="section-title-2 mb-10">
          Elevate Your Digital Presence with Our Dynamic Web Design Solutions
    </h2>
    <p>
      Transform your online presence with our cutting-edge Dynamic Web Design
      solutions. We offer a seamless and engaging user experience, empowering
      your website with dynamic features and a visually stunning interface.
      Customize your web design to reflect your brand identity and meet the
      unique needs of your audience.
    </p>
          <div className="text-center">
            <button className="btn1 mb-2 mb-lg-0">Explore Feature</button>
            <button className="btn1">Request Demo</button>
          </div>
        </div>
      </section>
    </>
  )
}
