import React from 'react';
import { Link } from 'react-router-dom';

export default function Navbar() {
  return (
    <>
    <header className="header-section d-lg-block d-none">
        <div className="header-bottom sticky-headers">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="main-menu">
                  <div className="logo">
                    <Link to="/">
                      {" "}
                      <img src="assets/image/logo.png" alt="" />
                    </Link>
                  </div>
                  <nav>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/portfolio">Portfolio</Link>
                      </li>
                      <li className="has-dropdown has-megaitem">
                        <Link to="/">
                          {" "}
                          Services <i className="fa fa-angle-down"></i>
                        </Link>

                        <div className="mega-menu">
                          <ul className="mega-menu-inner">
                            <li className="mega-menu-item">
                              <Link to="/" className="mega-menu-item-title">
                                Design & Branding
                              </Link>
                              <ul className="mega-menu-sub">
                                <li>
                                  <Link to="/brand-identity">
                                    Brand Identity
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/logo-design">Logo Design</Link>
                                </li>
                                <li>
                                  <Link to="/corporate-identity">
                                    Corporate Identity
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/brochure-design">
                                    Brochure Design
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/explainer-video">
                                    Explainer Video
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/content-writing">
                                    Content Writing
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li className="mega-menu-item">
                              <Link to="/" className="mega-menu-item-title">
                                Web Solutions
                              </Link>
                              <ul className="mega-menu-sub">
                                <li>
                                  <Link to="/website-design">
                                    Website Design
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/web-app-developement">
                                    Web Application Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/small-business-website">
                                    Small Business Website
                                  </Link>
                                </li>
                              </ul>
                              <div className="mega_menu gap_menu">
                                <Link to="/" className="mega-menu-item-title">
                                  Ecommerce Development
                                </Link>
                                <ul className="mega-menu-sub">
                                  <li>
                                    <Link to="/ecommerce-web-design">
                                      eCommerce Website Design
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/ecommerce-app">
                                      eCommerce App
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </li>

                            <li className="mega-menu-item">
                              <Link to="/" className="mega-menu-item-title">
                                App Development
                              </Link>
                              <ul className="mega-menu-sub">
                                <li>
                                  <Link to="/mobile-app-dev">
                                    Mobile App Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/android-app-dev">
                                    Android App Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ios-app-dev">
                                    iOS App Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/react-native-dev">
                                    React Native Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/hybrid-app-dev">
                                    Hybrid App Development
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li className="mega-menu-item">
                              <Link to="/" className="mega-menu-item-title">
                                Marketing & Lead Generation
                              </Link>
                              <ul className="mega-menu-sub">
                                <li>
                                  <Link to="/digital-marketing">
                                    Digital Marketing
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/seo-services">SEO Services</Link>
                                </li>
                                <li>
                                  <Link to="/social-media-marketing">
                                    Social Media Marketing
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ppc-management">
                                    PPC Management
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/local-seo">Local SEO</Link>
                                </li>
                                <li>
                                  <Link to="/content-marketing">
                                    Content Marketing Services
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li className="has-dropdown has-megaitem">
                        <Link to="/">
                          {" "}
                          Technology <i className="fa fa-angle-down"></i>
                        </Link>

                        <div className="mega-menu">
                          <ul className="mega-menu-inner">
                            <li className="mega-menu-item">
                              <Link to="/" className="mega-menu-item-title">
                                Web Technolgies
                              </Link>
                              <ul className="mega-menu-sub">
                                <li>
                                  <Link to="/nodejs-dev">
                                    Node JS Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/angular-dev">
                                    Angular Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/react-dev">
                                    React Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/php-dev">
                                    {" "}
                                    PHP Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/mongodb-dev">MongoDB</Link>
                                </li>
                              </ul>
                            </li>

                            <li className="mega-menu-item">
                              <Link to="/" className="mega-menu-item-title">
                                CMS Technologies
                              </Link>
                              <ul className="mega-menu-sub">
                                <li>
                                  <Link to="/wordpress-dev">
                                    Wordpress Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/drupal-dev">
                                    Drupal Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/joomla-dev">
                                    Joomla Development
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li className="mega-menu-item">
                              <Link to="/" className="mega-menu-item-title">
                                eCommerce Technologies
                              </Link>
                              <ul className="mega-menu-sub">
                                <li>
                                  <Link to="/magento-dev">
                                    {" "}
                                    Magento Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/shopify-dev">
                                    Shopify Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/big-commerce-dev">BigCommerce</Link>
                                </li>
                                <li>
                                  <Link to="/woo-commerce-dev">
                                    WooCommerce Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/open-cart-dev">
                                    OpenCart Development
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li className="mega-menu-item">
                              <Link to="/" className="mega-menu-item-title">
                                Mobile Technologies
                              </Link>
                              <ul className="mega-menu-sub">
                                <li>
                                  <Link to="/android-app-dev">
                                    Andriod Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ios-app-dev">
                                    iOS Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/react-native-dev">
                                    React Native Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/swift-app-dev">
                                    Swift Development
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/jquery-mobile">
                                    jQuery Mobile
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/iconic-dev">
                                    Iconic Development
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <Link to="/clients">Clients</Link>
                      </li>

                      <li className="has-dropdown">
                        <Link to="/">
                          {" "}
                          Hire Us <i className="fa fa-angle-down"></i>
                        </Link>

                        <ul className="sub-menu">
                          <li>
                            <Link to="/hire-angular-dev">
                              Hire Angular Developer
                            </Link>
                          </li>
                          <li>
                            <Link to="/hire-node-dev">
                              Hire Node JS Developer
                            </Link>
                          </li>
                          <li>
                            <Link to="/hire-laravel-dev">
                              Hire Laravel Developer
                            </Link>
                          </li>
                          <li>
                            <Link to="/hire-php-dev">
                              Hire PHP Developer
                            </Link>
                          </li>
                          <li>
                            <Link to="/hire-wordpress-dev">
                              Hire WordPress Developer
                            </Link>
                          </li>
                          <li>
                            <Link to="/hire-android-dev">
                              Hire Android Developer
                            </Link>
                          </li>
                          <li>
                            <Link to="/hire-ios-dev">
                              Hire iOS Developer
                            </Link>
                          </li>
                          <li>
                            <Link to="/hire-renative-dev">
                              Hire React Native Developer
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="has-dropdown">
                        <Link to="/">
                          Solutions <i className="fa fa-angle-down"></i>
                        </Link>

                        <ul className="sub-menu">
                          <li>
                            <Link to="/food-delivery-app">
                              Food Delivery App
                            </Link>
                          </li>
                          <li>
                            <Link to="/ecommerce-mobile-app">
                              eCommerce Mobile App
                            </Link>
                          </li>
                          <li>
                            <Link to="/elearning-solution">
                              eLearning Solutions
                            </Link>
                          </li>
                          <li>
                            <Link to="/property-listing-solution">
                              Property Listing Solutions
                            </Link>
                          </li>
                          <li>
                            <Link to="/shopping-cart-dev">
                              Shopping Cart Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/dynamic-web-dev">
                              Dynamic Web Design
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/blogs">Blogs</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
