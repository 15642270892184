import React from 'react'

export default function Header() {
  return (
    <>
    <div className="top_ber">
        <div className="container container-flex">
          <div className="topber_left">
            <ul className="list-unstyled contact-us-icon">
            {/* <li>
                <a>
                  <img src="assets/image/gif/call-service.gif" alt="" /> +91 9432205976
                </a>
              </li> */}
              <li>
                <a href="tel:+91 9432205976">
                  <img src="assets/image/india.png" alt="" /> +91 9432205976
                </a>
              </li>
              <li>
                <a href="tel:+441223968001">
                  <img src="assets/image/uk.png" alt="" /> +441223968001
                </a>
              </li>
              <li>
                <a href="tel:+13157158485">
                  <img src="assets/image/usa.png" alt="" /> +13157158485
                </a>
              </li>
              <li>                  
                  <a href="tel:+31852087966"> <img src="assets/image/netherland.png" alt=""/> +31 85 208 7966</a>
              </li>
              <li>                      
                <a href="tel:+3225862296"> <img src="assets/image/belgium.webp" alt=""/> +32 2 586 22 96</a>
              </li>
            </ul>
          </div>
          <div className="tober_right">
            <a href="mailto:info@adretsoftware.com">
              <i className="fa fa-envelope"></i> info@adretsoftware.com
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
