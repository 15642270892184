import React,{useState} from 'react'
import OurClient from '../OurClient/OurClient'

export default function SeoPrice() {
    const [price, setPrice] = useState({ silver: 249, gold: 499 });
  const [symbol, setSymbol] = useState(true);
    const handleToggle = () => {    
        let rate = 0.93;
        setSymbol(!symbol);
        if (symbol === true) {
          setPrice({
            silver: Math.round(249* rate),
            gold: Math.round(499 * rate),
          });
        } else {
          setPrice({
            silver: 249,
            gold: 499,
          });
        }
      };
  return (
    <>
    <div className="inner-page" id="contact-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="inner_content" data-aos="fade-up" data-aos-duration="2000">
                            <h1>SEO</h1>
                            <div aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#"><i className="ri-home-4-line"></i> Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">SEO</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="seo_content_sec">
            <div className="container">
                
                <div className="row">
                    <div className="col-g-12 text-right">
                        <div className="switch_butt">
                            <div className="form-check form-switch">
                                <label>$</label>
                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={handleToggle}/>
                                <label>€</label>
                              </div>
                        </div>
                       
                    </div>
                </div>
                <div className="row g-5 justify-content-center mt-3">
                    <div className="col-lg-6">
                        <div className="pricing_card" data-aos="zoom-in" data-aos-duration="2000">
                            <div className="top_header">
                                <h4>Silver</h4>
                                <div className="border_btn"></div>
                                <div className="pirze_sec">
                                {symbol ? "$" : "€"}
                            <span>{price.silver}</span>
                                </div>
                                <div className="con_month">
                                    <p className="mb-0">Per Month</p>
                                </div>

                                <div className="down_arow">
                                    <div className="down_arow_cont"></div>
                                    <i className="fa fa-arrow-down"></i>
                                </div>
                                <div className="over_heading">
                                    <span>Silver</span>
                                </div>
                            </div>
                            <div className="pricing_body">
                                <ul className="pricing_list">

                                    <li><i className="fa fa-check"></i> 3 Number of Keywords </li>
                                    <li><i className="fa fa-check"></i> 6 Months Timeline </li>
                                    <li><i className="fa fa-check"></i> Intensive Website Analysis </li>
                                    <li><i className="fa fa-check"></i> Competitor Analysis </li>
                                    <li><i className="fa fa-check"></i> Duplicate Content Check </li>
                                    <li><i className="fa fa-check"></i> Google Panda/Penguin Penalty Check </li>
                                    <li><i className="fa fa-check"></i> Keywords Research and Analysis </li>
                                    <li><i className="fa fa-check"></i> Dealing with Duplicate Issues </li>
                                    <li><i className="fa fa-check"></i> Google/Bing Webmaster Tools </li>
                                    <li><i className="fa fa-check"></i> Google Analytics Tracking Code Installation </li>
                                    <li><i className="fa fa-check"></i> XML Sitemaps Creation </li>
                                    <li><i className="fa fa-check"></i> RSS Feeds Creation </li>
                                    <li><i className="fa fa-check"></i> Optimizing Robots.txt </li>
                                    <li><i className="fa fa-check"></i> Canonicalization </li>
                                    <li><i className="fa fa-check"></i> Header Tags Optimization </li>
                                    <li><i className="fa fa-check"></i> Optimizing Title/Meta Tags </li>
                                    <li><i className="fa fa-check"></i> Image Optimization </li>
                                    <li><i className="fa fa-check"></i> Anchor Tag Optimization </li>
                                    <li><i className="fa fa-check"></i> Load Time Speed Optimization </li>
                                    <li><i className="fa fa-check"></i> 3 Blog post Per month </li>
                                    <li><i className="fa fa-check"></i> 4 Article Writing & Submission </li>
                                    <li><i className="fa fa-check"></i> Press Release Submission </li>
                                    <li><i className="fa fa-check"></i> Create Blogspot and Wordpress Account </li>
                                    <li><i className="fa fa-check"></i> Classified Submissions </li>
                                    <li><i className="fa fa-check"></i> Social Bookmarking </li>
                                    <li><i className="fa fa-check"></i> Article Social Bookmarking </li>
                                    <li><i className="fa fa-check"></i> Press Release Post Social Bookmarking </li>
                                    <li><i className="fa fa-check"></i> Website URLs Submission </li>
                                    <li><i className="fa fa-check"></i> Blog URLs Submission </li>
                                    <li><i className="fa fa-check"></i> Press Releases URLs Submission </li>
                                    <li><i className="fa fa-check"></i> Monthly SEO Reports </li>
                                    <li><i className="fa fa-check"></i> Google Analytics Report </li>
                                    <li><i className="fa fa-check"></i> Dedicated Account Managers </li>

                                </ul>
                                <a href="" className="btn_buy">BUY NOW</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="pricing_card" data-aos="zoom-in" data-aos-duration="2000">
                            <div className="top_header">
                                <h4>Gold</h4>
                                <div className="border_btn"></div>
                                <div className="pirze_sec">
                                {symbol ? "$" : "€"}
                            <span>{price.gold}</span>
                                </div>
                                <div className="con_month">
                                    <p className="mb-0">Per Month</p>
                                </div>

                                <div className="down_arow">
                                    <div className="down_arow_cont"></div>
                                    <i className="fa fa-arrow-down"></i>
                                </div>
                                <div className="over_heading">
                                    <span>Iphone App</span>
                                </div>
                            </div>
                            <div className="pricing_body">
                                <ul className="pricing_list">




                                    <li><i className="fa fa-check"></i> 6 Number of Keywords </li>
                                    <li><i className="fa fa-check"></i> 9 Months Timeline </li>
                                    <li><i className="fa fa-check"></i> Intensive Website Analysis </li>
                                    <li><i className="fa fa-check"></i> Backlinks Analysis </li>
                                    <li><i className="fa fa-check"></i> Competitor Analysis </li>
                                    <li><i className="fa fa-check"></i> Website Modification Consulting </li>
                                    <li><i className="fa fa-check"></i> Duplicate Content Check </li>
                                    <li><i className="fa fa-check"></i> Google Panda/Penguin Penalty Check </li>
                                    <li><i className="fa fa-check"></i> W3C Validation Check </li>
                                    <li><i className="fa fa-check"></i> Keywords Research and Analysis </li>
                                    <li><i className="fa fa-check"></i> Dealing with Duplicate Issues </li>
                                    <li><i className="fa fa-check"></i> Google/Bing Webmaster Tools </li>
                                    <li><i className="fa fa-check"></i> Google Analytics Tracking Code Installation </li>
                                    <li><i className="fa fa-check"></i> XML Sitemaps Creation </li>
                                    <li><i className="fa fa-check"></i> php Sitemap Creation </li>
                                    <li><i className="fa fa-check"></i> RSS Feeds Creation </li>
                                    <li><i className="fa fa-check"></i> Optimizing Robots.txt </li>
                                    <li><i className="fa fa-check"></i> Canonicalization </li>
                                    <li><i className="fa fa-check"></i> Header Tags Optimization </li>
                                    <li><i className="fa fa-check"></i> Optimizing Title/Meta Tags </li>
                                    <li><i className="fa fa-check"></i> Image Optimization </li>
                                    <li><i className="fa fa-check"></i> Anchor Tag Optimization </li>
                                    <li><i className="fa fa-check"></i> Hyperlinks Optimization </li>
                                    <li><i className="fa fa-check"></i> Existing Content Optimization </li>
                                    <li><i className="fa fa-check"></i> Structured Data Implementation </li>
                                    <li><i className="fa fa-check"></i> Load Time Speed Optimization </li>
                                    <li><i className="fa fa-check"></i> URL Re-Wrtiting (User Friendly) </li>
                                    <li><i className="fa fa-check"></i> Creation of New Landing Pages </li>
                                    <li><i className="fa fa-check"></i> 3 Blog post Per month </li>
                                    <li><i className="fa fa-check"></i> 4 Article Writing & Submission </li>
                                    <li><i className="fa fa-check"></i> Press Release Submission </li>
                                    <li><i className="fa fa-check"></i> Infographic Submission </li>
                                    <li><i className="fa fa-check"></i> Blog Setup </li>
                                    <li><i className="fa fa-check"></i> Create Blogspot and Wordpress Account </li>
                                    <li><i className="fa fa-check"></i> Classified Submissions </li>
                                    <li><i className="fa fa-check"></i> Social Bookmarking </li>
                                    <li><i className="fa fa-check"></i> Article Social Bookmarking </li>
                                    <li><i className="fa fa-check"></i> Press Release Post Social Bookmarking </li>
                                    <li><i className="fa fa-check"></i> Website URLs Submission </li>
                                    <li><i className="fa fa-check"></i> Blog URLs Submission </li>
                                    <li><i className="fa fa-check"></i> Press Releases URLs Submission </li>
                                    <li><i className="fa fa-check"></i> Yahoo Question & Answering </li>
                                    <li><i className="fa fa-check"></i> Monthly SEO Reports </li>
                                    <li><i className="fa fa-check"></i> Google Analytics Report </li>
                                    <li><i className="fa fa-check"></i> Dedicated Account Managers </li>

                                </ul>
                                <a href="" className="btn_buy">BUY NOW</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section className="logo_design_project p-0" id="android_app">
            <div className="row g-0 ">
                <div className="col-lg-5">
                    <div className="content_img aos-init aos-animate" data-aos="fade-right" data-aos-duration="2000">
                        <img src="assets/image/call-suprot-img.png" className="img-fluid" alt=""/>
                    </div>
                </div>
                <div className="col-lg-7 text-center">
                    <div className="heading aos-init aos-animate" data-aos="fade-left" data-aos-duration="2000">
                        <h3> Support For 24/7</h3>
                        <p>
                            What can we do for you? <br/>
We would be happy to discuss the project with you in person
                        </p>
                            <div className="btn_sec">
                                <a href="" className="btn2">Request a quote</a>
                            </div>
                    </div>
                </div>
            </div>
        </section>
        <OurClient/>
    </>
  )
}
