import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <>
    <footer>
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="ft_content">
                <div class="ft_logo">
                  <Link to="index.html">
                    <img src="assets/image/footer-logo.png" alt="" />{" "}
                  </Link>
                </div>
                <p>
                  Adret Software Services is one of the leading companies in the
                  web and software development industry. We have a team of
                  seasoned skills and creative minds who are deft at providing
                  perfect web solutions to give you a competitive edge.
                </p>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="ft_content">
                <h4>Quick Menu</h4>
                <ul class="list-unstyled ft_links">
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/career">Careers</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blog</Link>
                  </li>
                  <li>
                    <Link to="">Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to=""> Privacy Policy </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="ft_content ">
                <h4>Our Services</h4>
                <ul class="list-unstyled ft_links">
                  <li>
                    <Link to="/website-design"> Website Design</Link>
                  </li>
                  <li>
                    <Link to="web-app-developement"> Web Application Development</Link>
                  </li>
                  <li>
                    <Link to="/android-app-dev">Android Development</Link>
                  </li>
                  <li>
                    <Link to="/nodejs-dev">Node Js Development</Link>
                  </li>
                  <li>
                    <Link to="/wordpress-dev">Wordpress Development</Link>
                  </li>
                  <li>
                    <Link to="/digital-marketing"> Digital Marketing</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 ">
              <div class="ft_content ft_gap_left">
                <h4>Contact Info</h4>
                <ul class="list-unstyled ft_links2">
                  <li>
                    <i class="fa fa-globe"></i> Unit No.206, Dn-10, Merlin Matrix, DN Block, Sector V, Bidhannagar, Kolkata, West Bengal 700091
                  </li>
                  <li>
                    <Link to="">
                      <i class="fa fa-envelope"></i> info@adretsoftware.com
                    </Link>
                  </li>
                  <li>
                    <Link to="tel:+91 9432205976">
                      <span class="ft_call">
                        <img src="assets/image/call-bg1.gif" alt="" />
                      </span>{" "}
                      +91 9432205976
                    </Link>
                  </li>
                  <li>
                    <Link to="tel:+44 1223968001">
                      <span class="ft_call">
                        <img src="assets/image/call-bg2.gif" alt="" />
                      </span>
                      +44 1223968001
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <span class="ft_call">
                        <img src="assets/image/call-bg3.gif" alt="" />
                      </span>{" "}
                      +1 (315) 715 8485
                    </Link>
                    </li>
                  <li>
                    <Link to="">
                      <span class="ft_call">
                        <img src="assets/image/footer/netherland.png" alt="" />
                      </span>{" "}
                      +31 85 208 7966
                    </Link>
                    </li>
                  <li>
                    <Link to="">
                      <span class="ft_call">
                        <img src="assets/image/footer/belgium.webp" alt="" />
                      </span>{" "}
                      +32 2 586 22 96
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="ft_border"></div>
          <div class="copy_txt">
            <div class="row">
              <div class="col-lg-6 order-2 order-sm-2 order-lg-1">
                <p class="mb-0">
                  © 2021 Adret Software Services Pvt. Ltd. All rights reserved.
                </p>
              </div>
              <div class="col-lg-6 order-1 order-sm-1 order-lg-2">
                <div class="ft_socail">
                  <Link
                    to="https://www.facebook.com/AdretSoftwareServicePvtLtd"
                    target="_blank"
                  >
                    <i class="ri-facebook-fill"></i>
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/adret-software-services"
                    target="_blank"
                  >
                    <i class="ri-linkedin-fill"></i>
                  </Link>
                  <Link
                    to="https://www.youtube.com/channel/UC9PAKlTyzwICWxWajFrrNfg"
                    target="_blank"
                  >
                    <i class="ri-youtube-line"></i>
                  </Link>
                  <Link
                    to="https://in.pinterest.com/Adretsoftwareservices/_saved/"
                    target="_blank"
                  >
                    <i class="ri-pinterest-line"></i>
                  </Link>
                  <Link
                    to="https://www.instagram.com/adret.software/"
                    target="_blank"
                  >
                    <i class="ri-instagram-line"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
