import React, { useState } from "react";
import { Link } from "react-router-dom";
import OurClient from "../OurClient/OurClient";

export default function PricingPage() {
  const [show, setShow] = useState(1);
  const [price, setPrice] = useState({ silver: 649, gold: 799 });
  const [symbol, setSymbol] = useState(true);
  let golddoller = 799;
  let silverdoller = 649;
  const handleToggle = () => {
    if (show == 1) {
      golddoller = 799;
      silverdoller = 649;
    } else if (show == 2) {
      golddoller = 1499;
      silverdoller = 1099;
    } else if (show == 3) {
      golddoller = 100;
      silverdoller = 60;
    } else if (show == 4) {
      golddoller = 600;
      silverdoller = 400;
    } else {
      golddoller = 700;
      silverdoller = 400;
    }

    let rate = 0.93;
    setSymbol(!symbol);
    if (symbol === true) {
      setPrice({
        silver: Math.round(silverdoller * rate),
        gold: Math.round(golddoller * rate),
      });
    } else {
      setPrice({
        silver: silverdoller,
        gold: golddoller,
      });
    }
  };
  return (
    <>
      <section id="pricing-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-11 text-white">
              <div
                className="heading"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h2>Web Design and Development Packages</h2>
                <p>
                  Adret Software offers tailored Web Design and Development
                  Packages to elevate your online presence. Our Basic Package
                  includes a customized template with responsive design,
                  ensuring optimal user experience across devices. Upgrade to
                  the Standard Package for advanced features, intuitive
                  navigation, and CMS integration. For those venturing into
                  e-commerce, our specialized E-commerce Package delivers a
                  seamless online shopping experience with secure payment
                  gateways. Choose our Custom Package for a unique, bespoke
                  design and cutting-edge functionalities.
                </p>
                <div className="btn_sec">
                  <a href="" className="btn1">
                    Request A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_menu_sec sec_padding bg_color1">
        <div className="container">
          <div className="row">
            <div className="col-g-12 text-right">
              <div className="switch_butt">
                <div className="form-check form-switch">
                  <label>$</label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    onClick={() => handleToggle()}
                  />
                  <label>€</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-below">
            <div className="col-lg-12">
              <div id="v-pills-tab" data-aos="fade-up" data-aos-duration="2000">
                <ul
                  className=" nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={() => {
                        setShow(1);
                        setPrice({
                          silver: 647,
                          gold: 799,
                        });
                      }}
                      className={show == 1 ? "nav-link active" : "nav-link"}
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      {" "}
                      Website Development
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={() => {
                        setShow(2);
                        setPrice({
                          silver: 1099,
                          gold: 1499,
                        });
                      }}
                      className={show == 2 ? "nav-link active" : "nav-link"}
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Ecommerce Development
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={() => {
                        setShow(3);
                        setPrice({
                          silver: 60,
                          gold: 100,
                        });
                      }}
                      className={show == 3 ? "nav-link active" : "nav-link"}
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Graphic Design
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={() => {
                        setShow(4);
                        setPrice({
                          silver: 400,
                          gold: 600,
                        });
                      }}
                      className={show == 4 ? "nav-link active" : "nav-link"}
                      id="pills-contact-tab1"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact1"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Hosting
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={() => {
                        setShow(5);
                        setPrice({
                          silver: 400,
                          gold: 700,
                        });
                      }}
                      className={show == 5 ? "nav-link active" : "nav-link"}
                      id="pills-contact-tab2"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact2"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Mobile App Development
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={() => setShow(6)}
                      className={show == 6 ? "nav-link active" : "nav-link"}
                      id="pills-contact-tab3"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact3"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Digital Marketing
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 row-below">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className={
                    show == 1 ? "tab-pane fade show active" : "tab-pane fade"
                  }
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="row g-2 g-lg-5 justify-content-center">
                    <div className="col-lg-5">
                      <div
                        className="pricing_card"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="top_header">
                          <h4>Silver</h4>
                          <div className="border_btn"></div>
                          <div className="pirze_sec">
                            {symbol ? "$" : "€"}
                            <span>{price.silver}</span>
                          </div>

                          <div className="down_arow">
                            <div className="down_arow_cont"></div>
                            <i className="fa fa-arrow-down"></i>
                          </div>
                          <div className="over_heading">
                            <span>Silver</span>
                          </div>
                        </div>
                        <div className="pricing_body">
                          <ul className="pricing_list">
                            <li>
                              <i className="fa fa-check"></i>5 Web Pages
                            </li>
                            <li>
                              <i className="fa fa-check"></i> 1 Static Banner
                            </li>
                            <li>
                              <i className="fa fa-check"></i>SEO Friendly URLs
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Content Management
                              System
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Contact Us Page
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Google Map on
                              Contact Us Page
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Displaying Social
                              Media Links
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Favicon
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Support for Latest
                              Browsers
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Post Go Live
                              Support for 1 Month
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Dedicated Account
                              Managers
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Customer Support
                              (Email, Chat & Telephone)
                            </li>
                          </ul>
                          <a href="" className="btn_buy">
                            BUY NOW
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div
                        className="pricing_card"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="top_header">
                          <h4>Gold</h4>
                          <div className="border_btn"></div>
                          <div className="pirze_sec">
                            {symbol ? "$" : "€"}
                            <span>{price.gold}</span>
                          </div>

                          <div className="down_arow">
                            <div className="down_arow_cont"></div>
                            <i className="fa fa-arrow-down"></i>
                          </div>
                          <div className="over_heading">
                            <span>Gold</span>
                          </div>
                        </div>
                        <div className="pricing_body">
                          <ul className="pricing_list">
                            <li>
                              <i className="fa fa-check"></i>7 Web Pages
                            </li>
                            <li>
                              <i className="fa fa-check"></i>2 Static Banner
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Animated Header
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Logo
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Content Management
                              System
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Basic SEO
                            </li>
                            <li>
                              <i className="fa fa-check"></i>SEO Friendly URLs
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Contact Us Page{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Google Map on
                              Contact Us Page
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Displaying Social
                              Media Links
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Favicon
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Support for Latest
                              Browsers
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Post Go Live
                              Support for 1 Month
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Dedicated Account
                              Managers
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Customer Support
                              (Email, Chat & Telephone)
                            </li>
                          </ul>
                          <a href="" className="btn_buy">
                            BUY NOW
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    show == 2 ? "tab-pane fade show active" : "tab-pane fade"
                  }
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="row g-5 justify-content-center">
                    <div className="col-lg-5">
                      <div
                        className="pricing_card"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="top_header">
                          <h4>Silver</h4>
                          <div className="border_btn"></div>
                          <div className="pirze_sec">
                            {symbol ? "$" : "€"}
                            <span>{price.silver}</span>
                          </div>
                          <div className="down_arow">
                            <div className="down_arow_cont"></div>
                            <i className="fa fa-arrow-down"></i>
                          </div>
                          <div className="over_heading">
                            <span>Silver</span>
                          </div>
                        </div>

                        <div className="pricing_body">
                          <ul className="pricing_list">
                            <li>
                              <i className="fa fa-check"></i> 3 Static Banner{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Adv. Content
                              Management System{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Shopping Cart{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> 1 Payment Gateway{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Customer Login
                              Panel{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Ecommerce Features{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Basic SEO{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> SEO Friendly URLs{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Contact Us Page{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Thank you Page{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Website Parameters
                              Check{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Google Map on
                              Contact Us Page{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Responsive Design{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Displaying Social
                              Media Links{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Favicon{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Support for Latest
                              Browsers{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Post Go Live
                              Support for 1 Month
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Dedicated Account
                              Managers{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Customer Support
                              (Email, Chat & Telephone){" "}
                            </li>
                          </ul>

                          <a href="" className="btn_buy">
                            BUY NOW
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div
                        className="pricing_card"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="top_header">
                          <h4>Gold</h4>
                          <div className="border_btn"></div>
                          <div className="pirze_sec">
                            {symbol ? "$" : "€"}
                            <span>{price.gold}</span>
                          </div>
                          <div className="down_arow">
                            <div className="down_arow_cont"></div>
                            <i className="fa fa-arrow-down"></i>
                          </div>
                          <div className="over_heading">
                            <span>Gold</span>
                          </div>
                        </div>
                        <div className="pricing_body">
                          <ul className="pricing_list">
                            <li>
                              <i className="fa fa-check"></i>3 Static Banner
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Adv. Content
                              Management System
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Shopping Cart
                            </li>
                            <li>
                              <i className="fa fa-check"></i>1 Payment Gateway
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Customer Login
                              Panel
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Ecommerce Features
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Blog
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Basic SEO
                            </li>
                            <li>
                              <i className="fa fa-check"></i>SEO Friendly URLs
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Contact Us Page
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Thank you Page
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Website Parameters
                              Check
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Google Map on
                              Contact Us Page
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Google Analytics
                              Set-Up
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Google Webmasters
                              Set-Up
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Responsive Design
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Displaying Social
                              Media Links
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Favicon
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Support for Latest
                              Browsers
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Post Go Live
                              Support for 1 Month
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Dedicated Account
                              Managers
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Customer Support
                              (Email, Chat & Telephone)
                            </li>
                          </ul>
                          <a href="" className="btn_buy">
                            BUY NOW
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    show == 3 ? "tab-pane fade show active" : "tab-pane fade"
                  }
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <div className="row g-5 justify-content-center">
                    <div className="col-lg-5">
                      <div
                        className="pricing_card"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="top_header">
                          <h4>Silver</h4>
                          <div className="border_btn"></div>
                          <div className="pirze_sec">
                          {symbol ? "$" : "€"}
                            <span>{price.silver}</span>
                          </div>
                          <div className="down_arow">
                            <div className="down_arow_cont"></div>
                            <i className="fa fa-arrow-down"></i>
                          </div>
                          <div className="over_heading">
                            <span>Silver</span>
                          </div>
                        </div>
                        <div className="pricing_body">
                          <ul className="pricing_list">
                            <li>
                              <i className="fa fa-check"></i>2 Unique Designs
                              Samples
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Upto 48hrs
                              Turnaround Time
                            </li>
                            <li>
                              <i className="fa fa-check"></i> 2 Revision
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Full Copyright
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Editable Vector
                              File (.ai, .eps)
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Logo Files For Web
                              Usage (.jpg, .png, .gif)
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Dedicated Account
                              Managers
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Customer Support
                              (Email, Chat & Telephone)
                            </li>
                          </ul>
                          <a href="" className="btn_buy">
                            BUY NOW
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div
                        className="pricing_card"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="top_header">
                          <h4>Gold</h4>
                          <div className="border_btn"></div>
                          <div className="pirze_sec">
                          {symbol ? "$" : "€"}
                            <span>{price.gold}</span>
                          </div>
                          <div className="down_arow">
                            <div className="down_arow_cont"></div>
                            <i className="fa fa-arrow-down"></i>
                          </div>
                          <div className="over_heading">
                            <span>Gold</span>
                          </div>
                        </div>
                        <div className="pricing_body">
                          <ul className="pricing_list">
                            <li>
                              <i className="fa fa-check"></i> 4 Unique Designs
                              Samples{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Upto 48hrs
                              Turnaround Time{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> 4 Revision{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> 1 Redraws{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Full Copyright{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Editable Vector
                              File (.ai, .eps)
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Logo Files For Web
                              Usage (.jpg, .png, .gif){" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Dedicated Account
                              Managers{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Customer Support
                              (Email, Chat & Telephone){" "}
                            </li>
                          </ul>
                          <a href="" className="btn_buy">
                            BUY NOW
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    show == 4 ? "tab-pane fade show active" : "tab-pane fade"
                  }
                  id="pills-contact1"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab1"
                >
                  <div className="row g-5 justify-content-center">
                    <div className="col-lg-5">
                      <div
                        className="pricing_card"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="top_header">
                          <h4>Silver</h4>
                          <div className="border_btn"></div>
                          <div className="pirze_sec">
                          {symbol ? "$" : "€"}
                            <span>{price.silver}</span>
                          </div>
                          <div className="down_arow">
                            <div className="down_arow_cont"></div>
                            <i className="fa fa-arrow-down"></i>
                          </div>
                          <div className="over_heading">
                            <span>Silver</span>
                          </div>
                        </div>
                        <div className="pricing_body">
                          <ul className="pricing_list">
                            <li>
                              <i className="fa fa-check"></i>1 Year
                            </li>
                          </ul>
                          <a href="" className="btn_buy">
                            BUY NOW
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div
                        className="pricing_card"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="top_header">
                          <h4>Gold</h4>
                          <div className="border_btn"></div>
                          <div className="pirze_sec">
                          {symbol ? "$" : "€"}
                            <span>{price.gold}</span>
                          </div>
                          <div className="down_arow">
                            <div className="down_arow_cont"></div>
                            <i className="fa fa-arrow-down"></i>
                          </div>
                          <div className="over_heading">
                            <span>Gold</span>
                          </div>
                        </div>
                        <div className="pricing_body">
                          <ul className="pricing_list">
                            <li>
                              <i className="fa fa-check"></i>2 Year
                            </li>
                          </ul>
                          <a href="" className="btn_buy">
                            BUY NOW
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    show == 5 ? "tab-pane fade show active" : "tab-pane fade"
                  }
                  id="pills-contact2"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab2"
                >
                  <div className="row g-5 justify-content-center">
                    <div className="col-lg-5">
                      <div
                        className="pricing_card"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="top_header">
                          <h4>Android App Development</h4>
                          <div className="border_btn"></div>
                          <div className="pirze_sec">
                          {symbol ? "$" : "€"}
                            <span>{price.silver}</span>
                          </div>
                          <div className="con_month">
                            <p className="mb-0">Per Month</p>
                          </div>

                          <div className="down_arow">
                            <div className="down_arow_cont"></div>
                            <i className="fa fa-arrow-down"></i>
                          </div>
                          <div className="over_heading">
                            <span>Android App</span>
                          </div>
                        </div>
                        <div className="pricing_body">
                          <ul className="pricing_list">
                            <li>
                              <i className="fa fa-check"></i>Custom native
                              Android app
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Lifestyle, travel
                              app
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Marketing &
                              advertisement apps
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Business &
                              multimedia solutions
                            </li>
                          </ul>
                          <a href="" className="btn_buy">
                            BUY NOW
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div
                        className="pricing_card"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="top_header">
                          <h4>Iphone App Developement</h4>
                          <div className="border_btn"></div>
                          <div className="pirze_sec">
                          {symbol ? "$" : "€"}
                            <span>{price.gold}</span>
                          </div>
                          <div className="con_month">
                            <p className="mb-0">Per Month</p>
                          </div>

                          <div className="down_arow">
                            <div className="down_arow_cont"></div>
                            <i className="fa fa-arrow-down"></i>
                          </div>
                          <div className="over_heading">
                            <span>Iphone App</span>
                          </div>
                        </div>
                        <div className="pricing_body">
                          <ul className="pricing_list">
                            <li>
                              <i className="fa fa-check"></i> Custom native
                              iPhone apps
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Game apps
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Enterprise iPhone
                              apps
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Social networking
                              apps
                            </li>
                          </ul>
                          <a href="" className="btn_buy">
                            BUY NOW
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div
                        className="pricing_card"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="top_header">
                          <h4>php5 Hybrid App Development</h4>
                          <div className="border_btn"></div>
                          <div className="pirze_sec">
                          {symbol ? "$" : "€"}
                            <span>{price.silver}</span>
                          </div>
                          <div className="con_month">
                            <p className="mb-0">Per Month</p>
                          </div>

                          <div className="down_arow">
                            <div className="down_arow_cont"></div>
                            <i className="fa fa-arrow-down"></i>
                          </div>
                          <div className="over_heading">
                            <span>Hybrid App</span>
                          </div>
                        </div>
                        <div className="pricing_body">
                          <ul className="pricing_list">
                            <li>
                              <i className="fa fa-check"></i> php5 Web apps{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> php5 app
                              development{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> php5 Web apps{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> Email Accounts: 4{" "}
                            </li>
                            <li>
                              <i className="fa fa-check"></i> php5 social media
                              apps{" "}
                            </li>
                          </ul>
                          <a href="" className="btn_buy">
                            BUY NOW
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    show == 6 ? "tab-pane fade show active" : "tab-pane fade"
                  }
                  id="pills-contact3"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab3"
                >
                  <div
                    className="pricin_card"
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  >
                    <div className="container">
                      <div className="row g-4 justify-content-center">
                        <div className="col-lg-4">
                          <div className="pricing_content">
                            <Link to={"/seo-price"}>
                              <div className="pricing_img">
                                <img
                                  src="assets/image/Packages/packages-img1.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="btom">
                                <h4>SEO</h4>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="pricing_content">
                            <Link to={"/smo-price"}>
                              <div className="pricing_img">
                                <img
                                  src="assets/image/Packages/packages-img2.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="btom">
                                <h4>SMO</h4>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="pricing_content">
                            <Link to={"/gll-price"}>
                              <div className="pricing_img">
                                <img
                                  src="assets/image/Packages/packages-img3.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="btom">
                                <h4>GLL</h4>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="pricing_content">
                            <Link to={"/ssl-price"}>
                              <div className="pricing_img">
                                <img
                                  src="assets/image/Packages/packages-img4.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="btom">
                                <h4>SSL</h4>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="pricing_content">
                            <Link to={"/lead-gen-price"}>
                              <div className="pricing_img">
                                <img
                                  src="assets/image/Packages/packages-img5.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="btom">
                                <h4>Lead Generation</h4>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="logo_design_project p-0" id="android_app">
        <div className="row g-0 ">
          <div className="col-lg-5">
            <div
              className="content_img aos-init aos-animate"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <img
                src="assets/image/call-suprot-img.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7 text-center">
            <div
              className="heading aos-init aos-animate"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <h3> Support For 24/7</h3>
              <p>
                What can we do for you?
                <br />
                We would be happy to discuss the project with you in person
              </p>
              <div className="btn_sec">
                <a href="" className="btn2">
                  Request a quote
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OurClient />
    </>
  );
}
