import React from "react";

export default function ShoppingCartDev() {
  return (
    <>
      <div
        className="inner-page"
        id="nodejs-design-bg"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <h1>Shopping Cart Developement</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">
                        <i className="ri-home-4-line" />
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Shopping Cart Developement
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section" data-aos="fade-up" data-aos-duration={1500}>
        <div className="bg-body-tertiary">
          <div className="container py-3 py-lg-4">
            <div className="row align-items-center">
              <div className="col-md-7">
                <h2 className="section-title">
                  Explore the Features of Our Shopping Cart Development Solution{" "}
                </h2>
                <div className="d-flex align-items-center">
                  <i className="ri-pencil-ruler-line icon-color mr-3" />
                  <h5>Effortless Shopping Experience</h5>
                </div>
                <p className="">
                  Our Shopping Cart Development Solution provides a seamless and
                  efficient way to shop online. Users can easily add products to
                  their carts, manage quantities, and enjoy a hassle-free
                  shopping experience from browsing to checkout.
                </p>
                <div className="text-center text-md-left ">
                  <a href="contact.html" className="btn1 mb-2 mb-lg-0">
                    Explore Products
                  </a>
                </div>
              </div>
              <div className="col-md-5">
                <div className="bg-dotted">
                  <img
                    src="assets/image/desktop.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color "
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-courses.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="text-white">
                <h5 className="mb-3 ">
                  <img
                    width={64}
                    height={64}
                    src="https://img.icons8.com/ios/64/ffffff/map.png"
                    className="mr-3"
                    alt="map"
                  />
                  Intuitive Cart Management
                </h5>
                <p>
                  Experience seamless shopping with our intuitive cart
                  management system. Add, remove, or update items effortlessly.
                  The user-friendly interface ensures a smooth and enjoyable
                  shopping journey from selection to checkout.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-class-schedule.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <div className="d-flex align-items-center">
                  <i className="fa fa-calendar icon-color mr-3" />
                  <h5 className=""> Smart Product Recommendations</h5>
                </div>
                <p>
                  Enhance your shopping experience with intelligent product
                  recommendations. Our system analyzes your preferences and
                  suggests items tailored to your interests, making your
                  shopping journey more personalized.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color "
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-assessment.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="text-white">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    src="https://img.icons8.com/ios/50/ffffff/pass.png"
                    className="mr-3"
                    alt="pass"
                  />
                  Secure Checkout Process
                </h5>
                <p>
                  Shop confidently with our secure checkout process. Your
                  payment information is encrypted, ensuring a safe and
                  protected transaction. Enjoy peace of mind while completing
                  your purchase.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-specialists-guidance.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    src="https://img.icons8.com/sf-ultralight-filled/50/0d6efd/chat.png"
                    className="mr-3"
                    alt="chat"
                  />
                  Real-time Inventory Updates
                </h5>
                <p>
                  Stay informed about product availability with real-time
                  inventory updates. Our system ensures that you always know the
                  status of your selected items, preventing disappointments and
                  ensuring a smoother shopping experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section  bg_color"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-customization.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <div className="d-flex align-items-center">
                  <i className="fa fa-cogs icon-color-white mr-3" />
                  <h5 className="">Personalized Shopping Profiles</h5>
                </div>
                <p>
                  Create your personalized shopping profile for a tailored
                  experience. Save favorite items, track order history, and
                  receive recommendations based on your preferences, making
                  every visit to our platform uniquely yours.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-userfriendly.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={66}
                    height={66}
                    src="https://img.icons8.com/external-3d-design-circle/66/external-Admin-Panel-design-and-development-3d-design-circle.png"
                    alt="external-Admin-Panel-design-and-development-3d-design-circle"
                  />{" "}
                  Efficient Order Tracking
                </h5>
                <p>
                  Track your orders efficiently in real-time. Know the status of
                  your purchases from processing to delivery. Our user-friendly
                  tracking system keeps you informed and ensures a smooth and
                  transparent order fulfillment process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color  after-image overflow-hidden"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="mobile-friendly-image solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-mobile-friendly.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="text-white">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    className="mr-3"
                    src="https://img.icons8.com/ios/50/ffffff/media-queries.png"
                    alt="media-queries"
                  />
                  Responsive Mobile Experience
                </h5>
                <p>
                  Enjoy a seamless shopping experience on the go. Our platform
                  is designed to be fully responsive on mobile devices, allowing
                  you to browse, shop, and manage your cart effortlessly from
                  your smartphone or tablet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-report-generation.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={60}
                    height={60}
                    className="mr-3"
                    src="https://img.icons8.com/color/60/combo-chart--v1.png"
                    alt="combo-chart--v1"
                  />
                  Customer Support Assistance
                </h5>
                <p>
                  Need help? Our dedicated customer support team is here for
                  you. Get assistance with any inquiries, resolve issues, and
                  ensure a smooth shopping experience with our responsive and
                  knowledgeable support staff.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section  mb-10 py-3 py-lg-4"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container">
          <h2 className="section-title-2 mb-10">
            Unlock Effortless Shopping with Our Advanced Cart System
          </h2>
          <p>
            Take your online shopping to the next level and achieve unparalleled
            convenience with our cutting-edge Shopping Cart Development
            Solution. We provide a feature-rich and user-friendly experience,
            allowing you to customize the cart system according to your brand
            identity and specific business requirements.
          </p>
          <div className="text-center">
            <button className="btn1 mb-2 mb-lg-0">Explore Feature</button>
            <button className="btn1">Try Demo</button>
          </div>
        </div>
      </section>
    </>
  );
}
