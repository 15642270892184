import React from "react";
import { Link } from "react-router-dom";

export default function BackTop() {
  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="mobile-fixed-button">
        <Link to="/contact" className="call-btn" onClick={handleBackToTop}>
          {" "}
          <img src="assets/image/message.gif" alt="" /> Get In Touch
        </Link>
      </div>

      <div
        className="m-backtotop active"
        aria-hidden="true"
        onClick={handleBackToTop}
      >
        <div className="arrow">
          <i className="fa fa-angle-double-up" aria-hidden="true"></i>
        </div>
        <div className="text">Back to top</div>
      </div>
    </>
  );
}
