import React from 'react'

export default function PropertyListingSolution() {
  return (
    <>
      <div
        className="inner-page"
        id="nodejs-design-bg"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <h1>Property Listing Solution</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">
                        <i className="ri-home-4-line" />
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Property Listing Solution
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section" data-aos="fade-up" data-aos-duration={1500}>
        <div className="bg-body-tertiary">
          <div className="container py-3 py-lg-4">
            <div className="row align-items-center">
              <div className="col-md-7">
                <h2 className="section-title">
                Explore the Features of Our Property Listing Solution{" "}
                </h2>
                <div className="d-flex align-items-center">
                  <i className="ri-pencil-ruler-line icon-color mr-3" />
                  <h5>Easy Property Search</h5>
                </div>
                <p className="">
                Property Listings Solution provides a seamless and efficient way to
                  discover properties. Users can easily explore a diverse range
                  of listings, filter based on preferences, and find their dream
                  property with just a few clicks.
                </p>
                <div className="text-center text-md-left ">
                  <a href="contact.html" className="btn1 mb-2 mb-lg-0">
                    Find Properties
                  </a>
                  <button className="btn1">Explore</button>
                </div>
              </div>
              <div className="col-md-5">
                <div className="bg-dotted">
                  <img
                    src="assets/image/desktop.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color "
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-courses.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="text-white">
                <h5 className="mb-3 ">
                  <img
                     width={64}
                     height={64}
                     src="https://img.icons8.com/ios/64/ffffff/map.png"
                     className="mr-3"
                     alt="map"
                  />
                  Effortless Property Discovery
                </h5>
                <p>
            Our Property Listing Solution simplifies the property discovery process. Users can effortlessly explore a diverse range of listings, refine search criteria, and find their ideal property with ease.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-class-schedule.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <div className="d-flex align-items-center">
                  <i className="fa fa-calendar icon-color mr-3" />
                  <h5 className="">
                    {" "}
                    Detailed Property Insights
                  </h5>
                </div>
                <p>
            Get in-depth insights into each property with our solution. From pricing trends to neighborhood statistics, we provide comprehensive data to help you make informed decisions.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color "
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-assessment.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="text-white">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    src="https://img.icons8.com/ios/50/ffffff/pass.png"
                    className="mr-3"
                    alt="pass"
                  />
                 Secure Transaction Process
                </h5>
                <p>
            Our Property Listing Solution ensures secure and transparent transactions. From offer submission to closing deals, we prioritize the security of your property transactions.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-specialists-guidance.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    src="https://img.icons8.com/sf-ultralight-filled/50/0d6efd/chat.png"
                    className="mr-3"
                    alt="chat"
                  />
                  Interactive Property Maps
                </h5>
                <p>
            Explore properties with ease using our interactive maps. Visualize neighborhoods, nearby amenities, and property locations to make well-informed decisions.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section  bg_color"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-customization.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <div className="d-flex align-items-center">
                  <i className="fa fa-cogs icon-color-white mr-3" />
                  <h5 className="">Customer Reviews and Ratings</h5>
                </div>
                <p>
            Encourage user feedback and ratings to enhance property services. Our solution allows users to rate properties and provide valuable reviews for an improved property search experience.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-userfriendly.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={66}
                    height={66}
                    src="https://img.icons8.com/external-3d-design-circle/66/external-Admin-Panel-design-and-development-3d-design-circle.png"
                    alt="external-Admin-Panel-design-and-development-3d-design-circle"
                  />{" "}
                  Expert Guidance for Property Seekers
                </h5>
                <p>
            Navigate your property search with confidence through our expert guidance feature. Have questions about specific properties, neighborhoods, or the buying process? Our team of experienced professionals is ready to assist. Ask your queries, and our experts will provide valuable insights and recommendations promptly, ensuring you make informed decisions on your property journey.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color  after-image overflow-hidden"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="mobile-friendly-image solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-mobile-friendly.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="text-white">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    className="mr-3"
                    src="https://img.icons8.com/ios/50/ffffff/media-queries.png"
                    alt="media-queries"
                  />
                  Personalized Property Recommendations
                </h5>
                <p>
            Tailor your property search with personalized recommendations. Our solution analyzes your preferences to suggest properties that match your criteria, providing a unique and tailored experience.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-report-generation.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={60}
                    height={60}
                    className="mr-3"
                    src="https://img.icons8.com/color/60/combo-chart--v1.png"
                    alt="combo-chart--v1"
                  />
                  User-Friendly Property Management
                </h5>
                <p>
            Manage your property portfolio effortlessly with our user-friendly interface. From adding new listings to tracking property performance, our solution makes property management a breeze.
          </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section  mb-10 py-3 py-lg-4"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container">
          <h2 className="section-title-2 mb-10">
          Achieve Seamless Food Delivery with Our Advanced App
          </h2>
          <p>
          Elevate your food delivery service and achieve your business goals
      with our feature-rich and user-friendly Food Delivery App. We offer
      customization options to align the app with your brand and cater to
      your specific business needs.
          </p>
          <div className="text-center">
            <button className="btn1 mb-2 mb-lg-0">Explore Feature</button>
            <button className="btn1">Try Demo</button>
          </div>
        </div>
      </section>
    </>
  )
}
